import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Popo = ({ open, onClose }) => {
  const cancelButtonRef = useRef();
  // useEffect(() => {
  //   // Automatically close the dialog when it's opened
  //   if (open) {
  //     setTimeout(() => onClose(false), 50000); // Adjust the time as needed
  //   }
  // }, [open, onClose]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div> */}
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 sm:text-center underline"
                        >
                          सूचना:
                        </Dialog.Title>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "25px",
                            marginTop: "20px",
                          }}
                        >
                          <p className="text-sm text-[#212529]">
                            कोविड -19 महामारी से बचाव के सम्बंध में धार्मिक
                            स्थलों, मंदिरो के लिए केंद्र एवं राज्य सरकार द्वारा
                            जारी गाइड लाइन में दिए गये दिशा-निर्देश की पालना
                            सुनिश्चित करावे ।
                            <br />
                            ______________________________________________
                          </p>

                          <ol
                            type="1"
                            className="list-decimal pl-4 text-sm text-[#212529]"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <li> सामाजिक दूरी की अनुपालना ।</li>
                            <li> मुँह को मास्क से ढकना ।</li>
                            <li> जाँच एवं स्वच्छता ।</li>
                            <li>
                              {" "}
                              सेनेटाइजेशन सम्बंधी दिशा-निर्देशो की पूर्ण पालना
                              करना ।
                            </li>
                            <li>
                              धार्मिक स्थल में फूल माला, प्रसाद अन्य पूजन
                              सामग्री ले जाना एंव वितरित करना प्रतिबंधित रहेगा ।
                            </li>
                            <li>
                              {" "}
                              मंदिर परिसर के अन्दर स्थित घंटी घंटा बजाना पवित्र
                              जल (चरणामृत, शंखोदक) का छिड़काव करना भी प्रतिबंधित
                              रहेगा ।
                            </li>
                            <li>
                              {" "}
                              मंदिर परिसर में किसी भी वस्तु अथवा मूर्तियों को
                              छूने से बचे ।{" "}
                            </li>
                          </ol>
                          <p className="text-sm text-[#212529]">
                            {" "}
                            ऑनलाइन दर्शन का लाभ लेवे एंव ऑनलाइन दान करके
                            पुण्यलाभ अर्जित करे ।
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => onClose(false)}
                    >
                      Close
                    </button>
                    {/* <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => onClose(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Popo;
