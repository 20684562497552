
function GA_Event(screen_view_name , obj ){
  //GA4 Event
  if (obj) {
    if (typeof window !== 'undefined') {
    window.gtag("event", screen_view_name, {
      app_name: "ShriKushalBihari",
      ...obj,
    });
  }
  }
};

export default GA_Event;
