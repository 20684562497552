import React, { useContext } from "react";
import "./Header.css";
import { MyContext } from "../../MyContext";
const Header = () => {
  const { lang, setlang } = useContext(MyContext);
  return (
<>
    <div className="sticky top-0 z-50  max-md:hidden  hideOnMobile bg-[#f0f8ff] text-sm font-semibold marquee-container">
      <div  className="marquee flex justify-around">
        {lang === "eng" ? (
          <p>
            Information - Welcome to Direct Charge Temple: Shri Kushal Bihari,
            Devsthan Department, Government of Rajasthan. Take advantage of
            online darshans. Please donate online and receive blessings.
          </p>
        ) : (
          <p>
            सूचना - स्वागत है राजकीय आत्म निर्भर मंदिर : कुशल बिहारी जी,
            देवस्थान विभाग, राजस्थान सरकार | ऑनलाइन दर्शन का लाभ लेवे | ऑनलाइन
            दान करके पुण्यलाभ अर्जित करें |
          </p>
        )}
      </div>
      <div className="marquee marquee2 flex justify-around hideOnMobile ">
        {lang === "eng" ? (
          <p>
            Information - Welcome to Direct Charge Temple: Shri Kushal Bihari,
            Devsthan Department, Government of Rajasthan. Take advantage of
            online darshans. Please donate online and receive blessings.
          </p>
        ) : (
          <p>
            सूचना - स्वागत है राजकीय आत्म निर्भर मंदिर : कुशल बिहारी जी,
            देवस्थान विभाग, राजस्थान सरकार | ऑनलाइन दर्शन का लाभ लेवे | ऑनलाइन
            दान करके पुण्यलाभ अर्जित करें |
          </p>
        )}
      </div>
    </div>


      <div  className="Header-main md:hidden z-50 sticky top-0 hideOnMobile">
        {lang === "eng" ? (
          <p>
            Information - Welcome to Direct Charge Temple: Shri Kushal Bihari,
            Devsthan Department, Government of Rajasthan. Take advantage of
            online darshans. Please donate online and receive blessings.
          </p>
        ) : (
          <p>
            सूचना - स्वागत है राजकीय आत्म निर्भर मंदिर : कुशल बिहारी जी,
            देवस्थान विभाग, राजस्थान सरकार | ऑनलाइन दर्शन का लाभ लेवे | ऑनलाइन
            दान करके पुण्यलाभ अर्जित करें |
          </p>
        )}
      </div>

</>
  );
};

export default Header;
