import React, { useState } from "react";
import "./Gallery.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import MainBanner from "../MainBanner/MainBanner";
import img1 from '../../Asset/GalleryPageImage/img1.png'
import img2 from '../../Asset/GalleryPageImage/img2.png'
import img3 from '../../Asset/GalleryPageImage/img3.png'
import img4 from '../../Asset/GalleryPageImage/img4.png'
import img5 from '../../Asset/GalleryPageImage/img5.png'
import img6 from '../../Asset/GalleryPageImage/img6.png'
import img7 from '../../Asset/GalleryPageImage/img7.png'
import img8 from '../../Asset/GalleryPageImage/img8.png'
import img9 from '../../Asset/GalleryPageImage/img9.png'
import img10 from '../../Asset/GalleryPageImage/img10.png'
import img11 from '../../Asset/GalleryPageImage/img11.png'
import img12 from '../../Asset/GalleryPageImage/img12.png'
import img13 from '../../Asset/GalleryPageImage/img13.png'
import img14 from '../../Asset/GalleryPageImage/img14.png'
import img15 from '../../Asset/GalleryPageImage/img15.jpg'
import { useEffect } from "react";
import GA_Event from "../../utils/Analytics";

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const gallery = [img13, img14,img12,img10, img9, img1, img3, img4, img5, img6, img2, img3, img7, img15];

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

 useEffect(() => {
   
    GA_Event("home_page_landed_on_Gallery_page", {
      "event_name": "Landed_on_the_Gallery_page",
    });
   
   ;

  }, []);

  return (
    <>
      <div className="gallery-main">
        {/* <div className="temple-main-banner">
          <img
            src="https://www.rishabhdeo.org/images/about.jpg"
            alt=""
            loading="lazy"
          />
        </div> */}
        <MainBanner />
        <div id="gallery" className="temple-main-heading">
          
          <span>Gallery</span>
        </div>
        {/* <div className=" flex flex-wrap text- text-red-500 font-serif text-[20px] m-4 underline decoration-black "><span className="">Gallery</span></div>
         */}

        <div className="grid md:grid-cols-3 grid-cols-1 p-4 pt-0.5 items-center gap-4 mt-4  ">
          {gallery.map((item, index) => {
            return (
              <img
                src={item}
                alt=""
                // loading="lazy"
                key={index}
                onClick={() => openLightbox(index)}
              />
            );
            // s
          })}
          <iframe
              width="30%"
              className="w-[342px] md:w-[460px] md:h-[310px] h-[250px] shadow-2xl"
              // src="https://www.youtube.com/embed/XK4kKDIUv98?si=O1gY2-SsMicb51xL"
              src="https://www.youtube.com/embed/qZX14_9q9F4?si=UmxWIDiuKlMzJkcx"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
        </div>
        {isOpen && (



          <Lightbox
            mainSrc={gallery[photoIndex]}
            nextSrc={gallery[(photoIndex + 1) % gallery.length]}
            prevSrc={
              gallery[(photoIndex + gallery.length - 1) % gallery.length]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % gallery.length)
            }
          />
        )}
         <div className="flex flex-wrap justify-center pt-0.5">
           
             {/* <iframe
              width="30%"
              className="w-[280px] md:w-[450px] md:h-[350px] h-[250px] shadow-2xl"
              // src="https://www.youtube.com/embed/XK4kKDIUv98?si=O1gY2-SsMicb51xL"
              src="https://www.youtube.com/embed/qZX14_9q9F4?si=UmxWIDiuKlMzJkcx"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> */}
          </div>
      </div>
    </>
  );
};

export default Gallery;
