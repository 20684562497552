import React, { useContext } from "react";
import "./HeadingNanbar.css";
import { MyContext } from "../../MyContext";
import headingBanner from "../../Asset/ShriKushalBihari.png";
import vama from '../../Asset/HadingNavBar/vama.png'
import { Link } from "react-router-dom";
const HeadingNavbar = () => {
  const { lang, setlang } = useContext(MyContext);

  const handleLanguageChange = (newLang) => {
    setlang(newLang);
  };

  return (
    <div className="HeadingNavbar-main">
      <div className="HeadingNavbar-logo">
        <img src={headingBanner} alt="" />
      </div>
      <div className="hideOnMobile">
         <div className="HeadingNavbar-lang">
        
        <p onClick={() => handleLanguageChange("hin")}>हिन्दी</p>
        <p onClick={() => handleLanguageChange("eng")}> | English</p>
        <div className="px-0.5">
          <Link to={"https://vama.app/"}>
          <img className="h-10 w-12 pl-2" src={vama} alt="vama_icon"/>
        </Link>
        </div>
        
       
      </div>
      </div>
      
      
      
       
    </div>
  );
};

export default HeadingNavbar;
