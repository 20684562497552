import React, { useEffect, useState } from "react";
import "./LiveDarshan.css";
import MainBanner from "../MainBanner/MainBanner";
import axios from "axios";
import { parseCSV } from "../../utils/AboutUsData";
import GA_Event from "../../utils/Analytics";
const LiveDarshan = () => {
  // const [url, setUrl] = useState("");
  const [csvData, setCsvData] = useState([]);

  const fetchCSVData = () => {
    const csvUrl =
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vTpoevdVYT_dI14Scbp1T_QTIwIeSRXJUt98wwgczP2HLYcteXxA5OMlytzEGTyWj4MQS1jIQZHOW5h/pub?output=csv"; // Replace with your Google Sheets CSV file URL
    axios
      .get(csvUrl)
      .then((response) => {
        const parsedCsvData = parseCSV(response.data);
        setCsvData(parsedCsvData[0].url);
        // ...
      })
      .catch((error) => {
        // Handle errors
      });
  };

  // async function fetchApi() {
  //   axios.get(process.env.REACT_APP_GOOGLE_SHEET_URL).then((data) => {
  //     setUrl(data.data[0].youtube_url);
  //   });
  // }
  useEffect(() => {
    // fetchApi()
    fetchCSVData();
   
    GA_Event("home_page_landed_on_Donation_page", {
      "event_name": "Landed_on_the_Donation_page",
    });

  }, []);
  return (
    <>
      <div className="livedarshan-main">
        <MainBanner />
        <div id="live" className="temple-main-heading">
          <span>Live Darshan</span>
        </div>
        <div className=" flex flex-wrap pt-8">
          <iframe
            className="w-full md:h-[600px] h-[250px]"
            src={csvData}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen="true"></iframe>
        </div>
      </div>
    </>
  );
};

export default LiveDarshan;
