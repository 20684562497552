import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Logo from '../../Asset/FooterIcon/Logo.png'


const Footer = (props) => {
  return (
    <>
      <div className="Footer-main">
        <div className="Footer-main-1">
          <span>Made for</span>
          
        </div>
        <div className="Footer-main-2">
          <span>Devasthan Department, Rajasthan</span>
        </div>

        <div className="Footer-main-1">
          <span>Developed and Maintained by</span>
         
         
          {/* <span><Link to={"https://vama.app/"}>VAMA</Link></span> */}
        </div>
         <div className="flex flex-wrap justify-center  ">
         <Link to={"https://vama.app/"}>
             <img className="w-500 h-12 gap-4 p-2" src={Logo} alt="Logo" />
          </Link>
          </div>
          <div className="Footer-main-2">
    <p className="text-white text-xl font-[500]"> Visit Counter: {Number(props?.data?.view_count) + 1343} </p>
          </div>
        
      </div>
    </>
  );
};

export default Footer;
