export const AboutUs = {
  descriptions: [
    {
      description_english: `The Kushal Bihari Ji Temple can be found within the premises of the Radharani Lalana-Palan sports
       ground in Barsana, sitting atop the Dangad Choti on the Brahmanchal hill. This temple is located to the south of Chhata 
       tehsil on National Highway 2. The construction of this temple was done by Maharaja Madho Singh II at the request of 
       Maharani Kushal Kanwar, in honour of Radha Rani. Crafted from brown-coloured Bansi Pahadpur stone, the temple's 
       foundation was laid on Vaishakha Shukla Tritiya (Akshaya Tritiya) in 1918. The construction of this age-old temple 
       was carried out by the Public Works Department of the Jaipur state./Reputed as the most artistically remarkable temple in Barsana.`,
      description_hindi: `कुशल बिहारी जी मंदिर राधारानी की क्रीड़ास्थली लालन-पालन बरसाना में  स्थित है। मंदिर बरसाना में ब्रह्मांचल पर्वत पर दानगढ़ चोटी पर 
      स्थित है। यह मंदिर राष्ट्रीय राज मार्ग संख्या 2 से तहसील छाता से दक्षिण में स्थित हैं। इस मंदिर का निर्माण महाराजा माधोसिंह द्वितीय ने महारानी कुशल कवंर के 
      आग्रह पर राधा रानी के लिए करवाया था। मंदिर भूरे रंग के बंसी पहाड़ पूर के पत्थर से बना है। मंदिर का  पाटोत्सव वैशाख शुक्ल तृतीया (अक्षय तृतीया) 1918 में 
      हुआ। मंदिर का निर्माण जयपुर रियासत के लोक निर्माण विभाग द्वारा कराया गया था। कुशल बिहारी जी मंदिर बरसाना का सर्वाधिक कलात्मक मंदिर है।`,
    },
    {
      description_english: ` the construction of this temple spanned roughly 12 to 14 years, using massive stone blocks measuring 
      approximately 25-30 feet each. To transport these stones, the Maharaja of Jaipur had a metre-gauge railway line laid from 
      Mathura to Nandgaon.This grand temple, built from brown-coloured stone, is the largest temple in Barsana and is a treasure 
      trove of exquisite art. The main entrance of the temple, standing at approximately 50 feet in height, is graced with two 
      massive doors covered with brass sheets. These doors feature intricate carvings and artistic detailing. As one enters the main 
      gateway, two Tulsi Chauras (enclosures) on either side of the Nij Mandir can be seen, each with spaces for lighting 101 lamps. 
      These Tulsi Chauras showcase beautiful temple carvings manoeuvred in the Katuri style  `,
      description_hindi: `इस मंदिर निर्माण में लगभग 12 से 14 वर्ष लगे। मंदिर विशाल पाषाण खंडों  को जोड़कर बनाया गया है। लगभग 25-30 फुट के पाषाण खंड 
      इसमें लगे हुए हैं। इस मंदिर हेतु पत्थर लाने के लिए जयपुर महाराजा ने मथुरा से नन्दगांव तक मीटर गेज की लाइन डलवाई गई थी।
    भूरे रंग के पत्थरों से निर्मित श्री कुशल बिहारी जी मंदिर बरसाना का सबसे बडा मंदिर है, जो उत्कृष्ट कला का भंडार है। मंदिर का मुख्य द्वार लगभग 50 फुट ऊँचा है, 
    जिसमें पीतल की चादर चढ़े दो विशाल दरवाजे लगे हुए हैं। दरवाजे के दाएं  तथा बांयी ओर छोटी-छोटी तिवारियां बनी हुई हैं। दरवाजे के पत्थरों में विशेष नक्काशी से कलाकृतियाँ बनी हुई है।
    मुख्य द्वार में प्रवेश करने पर निज मंदिर दिखाई देता है निज मंदिर के सामने दोनों ओर तुलसी चौरा है जिनमें 101 दीपक रखने के स्थान बने हुए हैं। तुलसी चौरा पर सुन्दर नक्काशी की गई है। 
    कत्युरी शैली में इन पर छोटे-छोटे मंदिर बने हुए हैं।`,
    },
    {
      description_english: `Upon entering the temple, a Mangal Sthambh (auspicious pillar) is situated towards the right of the entrance, 
      which was erected when the temple's foundation was laid. `,
      description_hindi: `मंदिर में प्रवेश द्वार के दायीं ओर मंगल स्तम्भ स्थापित है, जिसकी स्थापना मंदिर की नींव लगाते समय ही की गई थी। `,
    },
    {
      description_english: `This pillar displays idols of Lord Ganesha, Lord Vishnu, Lord Shiva, and Lord Brahma. The Maharaja of Jaipur 
      had a Suryavanshi descent. Therefore, the entrance gate of the temple features a Sun made of stone, laden with embellishments of 
      Iranian glass, which, when illuminated by morning sunlight, casts colourful reflections onto the temple sill.`,
      description_hindi: ``,
    },
    {
      description_english: `The interior of the temple is adorned with beautiful stone lattice work on both sides.`,
      description_hindi: ``,
    },
    {
      description_english: `The entrance gate also displays intricate carvings of elephants, horses, and more.
    The temple is built in the shape of a fortress on the Brahmanchal hill. As one explores inside, Jagmohan and Rajputana 
    style balconies, above and below the temple respectively, add to its beauty. These balconies were used by the women of 
    the royal family to view the idol  of Thakur Shri Kushal Bihari Ji.`,
      description_hindi: `मंगल स्तम्भ पर भगवान गणेश, भगवान् विष्णु, भगवान शंकर एवं ब्रह्माजी की प्रतिमाएं बनी हुई है। जयपुर महाराज सूर्यवंशी थे अतः 
      मंदिर के प्रवेश द्वार के ऊपर ईरानी शीशे जड़ित पत्थर का सूर्य बना हुआ है, जिस पर प्रातः सूर्य की रोशनी पड़ने पर मंदिर के अन्दर भगवान की चौखट पर रंगीन प्रकाश पड़ता है।`,
    },
    {
      description_english: `The temple is supported by 12 massive pillars, and there are no garbha gruhas (inner sanctums) on the roof.`,
      description_hindi: ``,
    },
    {
      description_english: `Per the Vastu Shastra, these massive pillars are known as Mangal Kalash (Vastu Kumbh) that have a circumference 
      of 2 metres, making them unique examples of Vastu engineering. Additionally, there are 9 courtyards, each distinguished by its unique 
      design.`,
      description_hindi: `मंदिर के अन्दर दोनों ओर पत्थर की सुन्दर जालिया लगी हुई है।`,
    },
    {
      description_english: `Inside the temple, there are 9 fountains. A well, which earlier held accumulated water from seasonal rains, is 
      situated outside the temple in the northeast direction, and is used to supply water to the fountains. On the second level of the temple, 
      the water from the well was collected in a tank, and it was used to operate the fountains. Copper pipes extend from the temple's roof to facilitate this
       water supply.
      The idol of Thakur Shri Kushal Bihari Ji is placed in the centre of the temple. `,
      description_hindi: `मंदिर के प्रवेश द्वार पर हाथी, घोड़े आदि की सुन्दर नक्काशी बनी हुई हैं।
        मंदिर ब्रह्मांचल पर्वत पर किलेनुमा आकृति में बना हुआ है। मंदिर के अन्दर नीचे जगमोहन तथा ऊपर राजपुताना शैली की बालकनी बनी हुई हैं, जिनमें से राय घराने की औरतें ठा. श्री 
        कुशल बिहारी जी के दर्शन किया करती थी। मंदिर 12 विशाल खंभों पर टीका हुआ है  तथा छत पर कहीं भी गार्डर का प्रयोग नहीं है। `,
    },
    {
      description_english: `To its right side, you can find the idols of Thakur Shri Hansgopal Ji, along with the revered sage Narad Ji of the Nimbark Panth,
       and Nimbark Acharya Shri Nivas Acharya Shri.`,
      description_hindi: ``,
    },
    {
      description_english: `Alongside, idols of Thakur Ji's Bai and Thakur Shri Nrityagopal Ji are also placed.`,
      description_hindi: `इसके विशाल खम्भों को वास्तु शैली की भाषा में मंगल कलश कहा जाता है जिनका व्यास 2 मीटर है, यह वास्तु इजीनियरिंग 
      का अनूठा नमूना है। इसके अलावा 9 चौक है, जिनमें प्रत्येक का फर्श अलग-अलग डिजाइनों से बना हुआ है। मंदिर के अन्दर 9 फव्वारे हैं। फव्वारे चलाने के 
      लिए मंदिर के बाहर उत्तर-पूर्व दिशा में बावड़ी बनी हुई है। जिसमें बारीश का पानी एकत्रित होता था। मंदिर की दूसरी मंजिल पर बावड़ी के ऊपर गिरनी
      लगी हुई थी, जिससे पानी खींचकर फव्वारे चलाए जाते थे। ऊपर छत से मंदिर के भीतर तक फव्वारा पाइपलाइन ताम्बे की है। मंदिर मे ठाकुर श्री कुशल बिहारी जी मध्य में स्थापित है।`,
    },
    {
      description_english: `Thakur Shri Nath Gopal Ji is a movable idol, and can be placed per the requirements. 
      The primary temples of these three Thakurs have intricately crafted meenakari work on their marble doors.`,
      description_hindi: ``,
    },
    {
      description_english: `These doors also feature two attendants on either side, with a fan and a lotus bud 
      in their hands, displaying a serene expression. Additionally, on each Thakur Ji's door, there have been made five 
      small golden kalash.`,
      description_hindi: `इसके दांई ओर ठाकुर श्री हंसगोपाल जी व निम्बार्क पंचायत सनसनादिक ऋषि नारद जी, निम्बार्क आचार्य श्री निवासाचार्य श्री विराजमान है। `,
    },
    {
      description_english: `Thakur Shri Kushal Bihari Ji was consecrated by Nimbark Sampradayacharya.`,
      description_hindi: `ठाकुर जी के बाई और ठाकुर श्री नृत्य गोपाल जी विराजते है।`,
    },
    {
      description_english: `Two guards holding clubs can be seen at the temple's entrance gate. This temple features two pathways 
      for circumambulation (parikrama), with the first path dedicated to the three deities, and is present within the temple 
      premises. While the second, a more extensive path (larger parikrama), is present outside the temple.
      `,
      description_hindi: `ठाकुर श्री नत्थ गोपाल जी चल प्रतिमा है। इन तीनों ठाकुरो के मुख्य भवनों के द्वार पर संगमरमर के पत्थर पर आकर्षण मीनाकारी की गई है।`,
    },
    {
      description_english: `Regarding the temple, there's a recounted experience by former priests and attendants who describe that 
      within the temple, during the night, Shri Radheshyam Brahmacharya Ji engages in a nocturnal circumambulation ritual. Remarkably, 
      within the temple at night, the clocks and watches spontaneously chime, constituting a miraculous phenomenon of its own.`,
      description_hindi: `तीनों ठाकुरों के मुख्य भवनों के द्वार  पर दो-दो पार्षद प्रत्येक ठाकुर जी के द्वार पर चंवर और कमल कली लिए हुए प्रसन्न मुद्रा के बने हुए हैं। 
      प्रत्येक ठाकुर जी के द्वार पर सोने के छोटे पाँच-पाँच कलश लगे हुए हैं।`,
    },
    {
      description_english: `Further, the temple has two distinct kitchens designed for the preparation of both raw and cooked food 
      offerings. Inside the temple complex, there are approximately 72 rooms, big and small, designed to provide accommodation. The 
      rooms on the first floor are inter-connected, allowing for movement from one room to another. The temple is surrounded by walls 
      that are about 10 feet high.`,
      description_hindi: `ठाकुर श्री कुशल बिहारी जी निम्बार्क सम्प्रदाचार्य के द्वारा पघराए गए हैं। मंदिर के प्रवेश द्वार पर दो द्वारपाल हाथ में छडी लिए हुए विराजमान है, 
      इस मंदिर की दो परिक्रमा है प्रथम परिक्रमा मंदिर परिसर के अन्दर तीनो ठाकुर जी की है। दूसरी मंदिर के बाहर बड़ी परिकमा है। मंदिर के विषय में एक अनुभव की बात 
      पूर्व पूजारी व  सेवागीरों द्वारा बताते हैं कि उक्त मंदिर के अन्दर रात के समय श्री राधेश्याम ब्रह्माचार्य जी परिक्रमा लगाते हैं मंदिर के भीतर रात के समय घंटे-घडियाल स्वतः 
      बज उठते हैं। जो कि एक चमत्कार है। मंदिर में दो अलग-अलग भोग भण्डार कच्ची व पक्की रसोई हेतु बने हुए हैं। मुख्य मंदिर के चारों और आवास हेतु लगभग छोटे बड़े 72 
      कमरे बने हुए हैं। प्रथम मंजिल के कमरे इस तरह बने हुए हैं कि हर कमरे से दूसरे कमरे में आवागमन हो सकता है।`,
    },
    {
      description_english: `The temple of Shri Kushal Bihari Ji is renowned for its unique and miraculous experiences. It stands as a 
      living testament to India's ancient temple culture, and is diligently being maintained and preserved by the Devasthan Department 
      of the Rajasthan government.`,
      description_hindi: `सम्पूर्ण मंदिर प्रागंण के चारों और लगभग 10 फुट ऊंची दीवारें बनी हुई है। इस प्रकार देवस्थान विभाग राजस्थान सरकार द्वारा नियंत्रित यह कुशल बिहारी 
      जी जयपुर मंदिर बरसाना में स्थित कला का अद्भुत नमुना है, जो आज भी भारत की प्राचीन मंदिर संस्कृति का भाग बना हुआ है।`,
    },
  ],
};

export function parseCSV(csvText) {
  const rows = csvText.split(/\r?\n/); // Split CSV text into rows, handling '\r' characters
  const headers = rows[0].split(","); // Extract headers (assumes the first row is the header row)
  const data = []; // Initialize an array to store parsed data
  for (let i = 1; i < rows.length; i++) {
    const rowData = rows[i].split(","); // Split the row, handling '\r' characters
    const rowObject = {};
    for (let j = 0; j < headers.length; j++) {
      rowObject[headers[j]] = rowData[j];
    }
    data.push(rowObject);
  }
  return data;
}
