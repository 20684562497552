import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./component/Footer/Footer";
import Header from "./component/Header/Header";
import HeadingNavbar from "./component/HeadingNavbar/HeadingNavbar";
import HomePage from "./component/HomePage/HomePage";
import KeywordHeader from "./component/KeywordHeader/KeywordHeader";
import MainBanner from "./component/MainBanner/MainBanner";
import { MyContextProvider } from "./MyContext";
import AboutPage from "./component/AboutPage/AboutPage";
import Temple from "./component/TemplePage/Temple";
import LiveDarshan from "./component/LiveDarshan/LiveDarshan";
import Program from "./component/Program/Program";
import Donation from "./component/Donation/Donation";
import Gallery from "./component/Gallery/Gallery";
import ContactUs from "./component/ContactUs/ContactUs";
import Popo from "./component/Pop/Popo";
import { Navigate } from "react-router-dom";
import { UpdateCurrentUser, GetCurrentUser } from "./Services";



function App() {
  const [showPopo, setShowPopo] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    UpdateCurrentUser().then((res) => {
    });
    GetCurrentUser().then((res) => {
      setData(res.data);
    });
  }, [])

  return (
    <MyContextProvider>
      <Header />
      <HeadingNavbar />
      <KeywordHeader />
      <Routes>
       
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/aboutus" element={<AboutPage />} /> */}
        {/* <Route path="/temple" element={<Temple />} /> */}
        <Route path="/livedarshan" element={<LiveDarshan />} />
        <Route path="/program" element={<Program />} />
        <Route path="/donation" element={<Donation />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
      <Footer data={data} />
      <Popo open={showPopo} onClose={setShowPopo} />
    </MyContextProvider>
  );
}

export default App;
