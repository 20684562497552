import React from "react";
import "./Program.css";
import MainBanner from "../MainBanner/MainBanner";
import { useContext } from "react";
import { MyContext } from "../../MyContext";
import { useEffect } from "react";
import GA_Event from "../../utils/Analytics";


const Program = () => {
   const { lang} = useContext(MyContext);

   useEffect(() => {
   
    GA_Event("home_page_landed_on_Donation_page", {
      "event_name": "Landed_on_the_Donation_page",
    });
   
   ;

  }, []);

  return (
    <>
      <div className="program-main">
        {/* <div className="temple-main-banner">
          <img src="https://www.rishabhdeo.org/images/about.jpg" alt="" />
        </div> */}
        <MainBanner />
        {lang === "eng" ? (
    <React.Fragment>
          <div id="program" className="temple-main-heading">
          <span>Program</span>
        </div>
           
        <div className="program-main-program">
          <div className="program-main-program1">
            <table className="program-table">
              <thead className="program-tableHead">
                <tr>
                  <th>Summer</th>
                  <th>Winter</th>
                  <th>Event</th>
                </tr>
              </thead>
              <tbody
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "55px",
                }}
              >
                <tr className="program-tr1">
                  <td>5:00 AM</td>
                  <td>6:00 AM</td>
                  <td>Mangla Aarti</td>
                </tr>
                <tr className="program-tr">
                  <td>7:00 AM</td>
                  <td>8:30 PM</td>
                  <td>Shingaar Aarti</td>
                </tr>
                <tr className="program-tr1">
                  <td>12:00 PM</td>
                  <td>12:30 PM</td>
                  <td>Raajbhog Aarti</td>
                </tr>
                <tr className="program-tr1">
                  <td>4:30 PM</td>
                  <td>4:00 PM</td>
                  <td>Utthapan</td>
                </tr>
                <tr className="program-tr1">
                  <td>7:30 PM</td>
                  <td>6:30 PM</td>
                  <td>Sandhya Aarti</td>
                </tr>
                <tr className="program-tr1">
                  <td>9:00 PM</td>
                  <td>8:00 PM</td>
                  <td>Shyan Aarti</td>
                </tr>
               
              </tbody>
            </table>
          </div>
          <div className="pt-4 font-sans m-2">
          <p>There may be changes in timings on the day of the fair/festival.</p>
          </div>
        </div>
    </React.Fragment>
        ):(
          <React.Fragment>
                     <div className="temple-main-heading">
          <span>Program</span>
        </div>
           
        <div className="program-main-program">
          <div className="program-main-program1">
            <table className="program-table">
              <thead className="program-tableHead">
                <tr>
                  <th>ग्रीष्मकाल</th>
                  <th>शरदकाल</th>
                  <th>कार्यक्रम</th>
                </tr>
              </thead>
              <tbody
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "55px",
                }}
              >
                <tr className="program-tr1">
                  <td>5:00 AM</td>
                  <td>6:00 AM</td>
                  <td>मंगला आरती</td>
                </tr>
                <tr className="program-tr">
                  <td>7:00 AM</td>
                  <td>8:30 PM</td>
                  <td>शृंगार आरती</td>
                </tr>
                <tr className="program-tr1">
                  <td>12:00 PM</td>
                  <td>12:30 PM</td>
                  <td>राजभोग आरती</td>
                </tr>
                <tr className="program-tr1">
                  <td>4:30 PM</td>
                  <td>4:00 PM</td>
                  <td>उत्थापन</td>
                </tr>
                <tr className="program-tr1">
                  <td>7:30 PM</td>
                  <td>6:30 PM</td>
                  <td>संध्या आरती</td>
                </tr>
                <tr className="program-tr1">
                  <td>9:00 PM</td>
                  <td>8:00 PM</td>
                  <td>शयन आरती</td>
                </tr>
               
              </tbody>
            </table>
          </div>
          <div className="pt-4">
          <p>मेले / उत्सव के दिन समय मे परिवर्तन हो सकता है।</p>
          </div>
        </div>
          </React.Fragment>
        )}
        
      </div> 
    </>
  );
};

export default Program;
