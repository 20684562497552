import React, { useContext } from "react";
import "./HomePage.css";

import MainBanner from "../MainBanner/MainBanner";
import { MyContext } from "../../MyContext";
import { AboutUs } from "../../utils/AboutUsData";



const HomePage = () => {
  const { lang, setlang } = useContext(MyContext);
  
  return (
    <>
      <MainBanner />
      <div className="homePage-main">
        <h1 id="home" className="homePage-main-his">
          {lang === "eng" ? "Rajkiye Pratyaksh Prabhar Mandir - Shri Kushal Bihari Ji, Barsana" : 
          "राजकीय प्रत्यक्ष प्रभार मंदिर श्री कुशल बिहारी जी, बरसाना"}
        </h1>

        {lang === "eng" ? (
          // <div className="homePage-main1">
          <div className="flex flex-col text-justify gap-2 py-4 shadow-xl rounded-sm px-1">

            {AboutUs.descriptions.map((item,index) => {
              return <p key={index}>{item.description_english}</p>
            })}
            
          </div>
        ) : (
          <div className="homePage-main1">

             {AboutUs.descriptions.map((item,index) => {
              return <p key={index}>{item.description_hindi}</p>
            })}
           
          </div>
        )}
        
        
        {/* <div className="homePage-main1">
          <p>
            Among the oldest pilgrimage sites, the shrine of Lord Rishabhdev is
            superlative in terms of its grandeur, miracles and art. The four
            pilgrimage shrines of Mewar, Shrinathji, Shri Eklingji, Shri
            Charbhujaji and Shri Keshariji have special significance for
            different sects, including Rishabhdev shrine, Jain, non-Jains, Hindu
            tribal is a sacred place for all religious people. It is situated on
            the banks of the Kunwarika River, connected to the National Highway
            No. 8, 65 km from Udaipur District Headquarters, between the Kandras
            of Aravalli.
          </p>
          <p>
            Rishabhdevji temple of self-dependent category in temples managed
            and controlled by Devasthan Department, Rajasthan-Government, about
            105 cm in the dark color of Lord Rishabhdevji. A grand statue of a
            magnificent size sits in the temple built in the ridges of the hills
            in Rishabhdev village. Devotees have been worshiping this statue
            from Keshar since ancient times, hence they are called
            Keshariyanath. They are also called Kalaji Bavji, Kesharia Baba,
            Dhulevadhni, tribals of the local state.
          </p>
          <p>
            Every year, eight days after Holi, the birth anniversary of Lord
            Rishabhdev is celebrated on Chaitra Krishna Ashtami. A three-day
            fair (Saptami, Ashtami and Navami) is also held on this occasion. In
            which thousands of devotees from far-flung areas come for worship
            and service worship. On the occasion of Janmotsav festival area,
            Krishna Ashtami holds a huge procession of Lord Kesharia, birth
            welfare aarti and Mangal Deepak
          </p>
          <p>
            Apart from the Rishabhdev Temple, temples located in the town are
            also worshiped and arranged from the budget of temple Shri
            Rishabhdev.
          </p>
        </div> */}
       
        {/* <div className="homePage-main2">
          {/* <Donation /> */}
          {/* <DailyChart />
        </div>  */}
      </div>
    </>
  );
};

export default HomePage;
