import React from "react";
import "./Contact.css";
import MainBanner from "../MainBanner/MainBanner";

const ContactUs = () => {
  return (
    <>
      <div className="contact-main">
        {/* <div className="temple-main-banner">
          <img src="https://www.rishabhdeo.org/images/about.jpg" alt="" />
        </div> */}
        <MainBanner />
        <div id="contact" className="temple-main-heading">
          <span>Contact Us</span>
        </div>

        <div className="contact-main1">
        
          <div className="flex flex-col pb-8 pr-16">
            

            <p>Assistant Commissioner</p>

            <p>Devasthan Department, Jaipur Mandir</p>

            <p>Vrindavan Dis- Mathura</p>

            <p>Pincode - 281121</p>

            <p>Email id - ac.vrindavan.dev@rajasthan.gov.in</p>
          </div>
          
          <div className="">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3976.2598390135654!2d77.36699451979409!3d27.647132821946617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDM4JzQ5LjQiTiA3N8KwMjInMTIuNiJF!5e0!3m2!1sen!2sin!4v1702018644432!5m2!1sen!2sin" 
            width="600" 
            height="450" 
            style="border:0;" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
          </iframe> */}
            <iframe
            className="w-[340px] md:w-[500px] md:h-[500px] h-[250px] rounded-md shadow-2xl "
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3976.2598390135654!2d77.36699451979409!3d27.647132821946617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDM4JzQ5LjQiTiA3N8KwMjInMTIuNiJF!5e0!3m2!1sen!2sin!4v1702018644432!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              // style={{ width: "550px", height: "500px" }}
            ></iframe>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default ContactUs;
