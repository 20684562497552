import React from "react";
import Slider from "react-slick";
import "./MainBanner.css";
import Group14 from '../../Asset/MainBannerImage/Group14.png'

const MainBanner = () => {
  const ImageData = [
    "https://www.rishabhdeo.org/images/slide-1.jpg",
    "https://www.rishabhdeo.org/images/slide-3.jpg",
    "https://www.rishabhdeo.org/images/slide-2.jpg",
    "https://www.rishabhdeo.org/images/slide-1.jpg",
    
  ];
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="">
      <div className="">
        {/* <Slider {...settings}>
          {ImageData?.map((item, index) => {
            return <img key={index} src={item} alt="" className="a" />;
          })}
        </Slider> */}
        
            <img className="aspect-[511/227]" src={Group14} />
        
      </div>
    </div>
  );
};

export default MainBanner;
