import React, { useState } from "react";

const MyContext = React.createContext();
const MyContextProvider = ({ children }) => {

 
 const [lang, setlang] = useState("eng")



  const contextValue = {
    lang,
    setlang

  };
  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };
