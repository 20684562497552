import React,{useEffect} from "react";
import "./Donation.css";
import scanner from '../../Asset/QrImage/scanner.png'
import MainBanner from "../MainBanner/MainBanner";
import { MyContext } from "../../MyContext";
import { useContext } from "react";
import GA_Event from "../../utils/Analytics";

const Donation = () => {

  const { lang, setlang } = useContext(MyContext);

   useEffect(() => {
   
    GA_Event("home_page_landed_on_Donation_page", {
      "event_name": "Landed_on_the_Donation_page",
    });
   
   ;

  }, []);

  return (
    <>
      <div className="AboutPage-main">
        {/* <div className="temple-main-banner">
          <img src="https://www.rishabhdeo.org/images/about.jpg" alt="" />
        </div> */}
        <MainBanner />
        <div id="donation" className="temple-main-heading">
          <span>Donation</span>
        </div>

        <div className=" md:mx-40 ">
          <div className="donation-heading">
            <p>
              Kushal Bihari Donation Online donations are accepted here. Kindly
              scan QR code to donate and receive blessings.<br/> ऑनलाइन दान स्वीकार
              किया जाता है, कृपया दान करके पुण्य लाभ अर्जित करें|
            </p>
          </div>
          
          
          {/* <div className="flex justify-center>">
            <Form />
          </div> */}
          <div className=" flex flex-row justify-center w-full pt-1">
            <img src={scanner} alt="" />
           
          </div>
          <div className=" flex justify-center text-xl text-slate-700 font-bold">
           {lang === "eng" ? (
            <p>Scan and pay with any UPI App</p>
            ):(
              <p>किसी भी UPI ऐप के साथ स्कैन और भुगतान करें</p>
            )}
          </div>

          
        </div>
      </div>
    </>
  );
};

export default Donation;
