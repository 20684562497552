import axios from 'axios';

export const UpdateCurrentUser = async () => {
    try {
      return await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/update_view/kushalBihari/`
      );
    } catch (error) {
      return error;
    }
  };

  export const GetCurrentUser = async () => {
    
    try {
      return await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get_view/kushalBihari/`
      );
    } catch (error) {
      return error;
    }
  };