import React, { useContext, useState, useEffect } from "react";
import "./KeywordHeader.css";
import { NavLink, Link } from "react-router-dom";
import { MyContext } from "../../MyContext";
import vama from '../../Asset/HadingNavBar/vama.png'
import { GA_Event } from "../../utils/Analytics";


const KeywordHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { lang, setlang } = useContext(MyContext);

  const handleLanguageChange = (newLang) => {
    setlang(newLang);
  };

   // Function to scroll down when a button is clicked

   const [id,setId] = useState("")
   

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    
  };

  const toggle = (idname) => {
    setId(idname)
    setMenuOpen(false);
    handleButtonClick(id);
   
  }

  
  const handleButtonClick = (id) => {
    const element = document.getElementById(id)
    if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
};

  return (
    <div className="KeywordHeader-main">
      <div className={`menu ${isMenuOpen ? "open" : ""}  `}>
        <div className="flex justify-between items-center">
            <div className="menu-toggle" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          
        </div>
        
        <div className="md:hidden flex flex-row">
          <div className="flex flex-wrap">
                <p onClick={() => handleLanguageChange("hin")}>हिन्दी</p>
              <p onClick={() => handleLanguageChange("eng")}> | English</p>
          </div>
          <div className="px-0.5">
            <Link to={"https://vama.app/"}>
            <img className="h-9 w-12 pl-2" src={vama} alt="vama_icon"/>
          </Link>
          </div>
        </div>
      </div>
        <div>
          
        </div>
        <div className="navbar-nav-links-main">
          {lang === "eng" ? (
            <NavLink to="/" onClick={() => toggle("home")}>Home</NavLink>
          ) : (
            <NavLink to="/" onClick={() => toggle("home")}>होम</NavLink>
          )}
          {/* {lang === "eng" ? (
            <NavLink to="/aboutus">About Us</NavLink>
          ) : (
            <NavLink to="/aboutus">हमारे बारे में</NavLink>
          )} */}

          {/* {lang === "eng" ? (
            <NavLink to="/temple">Temple</NavLink>
          ) : (
            <NavLink to="/temple">मंदिर</NavLink>
          )} */}
          {lang === "eng" ? (
            <NavLink to="/livedarshan" onClick={() => toggle("live")} >Live Darshan</NavLink>
          ) : (
            <NavLink to="/livedarshan" onClick={() => toggle("live")}>लाइव दर्शन</NavLink>
          )}
          {lang === "eng" ? (
            <NavLink to="/program" onClick={() => toggle("program")}>Program</NavLink>
          ) : (
            <NavLink to="/program" onClick={() => toggle("program")}>कार्यक्रम</NavLink>
          )}
          {lang === "eng" ? (
            <NavLink to="/donation" onClick={() => toggle("donation")}>Donation</NavLink>
            
          ) : (
            <NavLink to="/donation" onClick={() => toggle("donation")}>दान</NavLink>
          )}
          {lang === "eng" ? (
            <NavLink to="/gallery" onClick={() => toggle("gallery")}>Gallery</NavLink>
          ) : (
            <NavLink to="/gallery" onClick={() => toggle("gallery")}>गेलरी</NavLink>
          )}
          {lang === "eng" ? (
            <NavLink to="/contactus" onClick={() => toggle("contact")}>Contact Us</NavLink>
          ) : (
            <NavLink to="/contactus" onClick={() => toggle("contact")}>संपर्क करें </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeywordHeader;
